import React from "react"

const A = props => {
  return (
    <a href={props.to} rel="nofollow noreferrer noopener" target="_blank">
      {props.text ? props.text : props.to}
    </a>
  )
}

export default A
